<script>
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Buy': 'Comprar',
    },
    es: {
      'Buy': 'Comprar',
    }
  },
  props: {
    id: {
      default: ""
    },
    variations: {
      default: ""
    },

  },
  components: { },
  data() {
    return {
      loading: false,
      variation: Object.keys(this.variations).shift(),
      selected: true,
      cart: {
        items: {
          total: 0,
          list: null
        },
        subtotal: 'R$ 0,00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: 'R$ 0,00'
      }
    };
  },
  methods: {
    buyProduct(variation) {
      if (variation) {
        this.loading = true

        api
          .post('store/cart', {
            id: this.variation,
            qty: 1
          })
          .then(response => {
            if (response.data.status=='success') {
              this.$noty.success('O produto foi adicionado em seu carrinho.')

              this.cart.items.total = response.data.items.total
              this.cart.items.list = response.data.items.list
              this.cart.subtotal = response.data.subtotal
              this.cart.shipping = response.data.shipping
              this.cart.total = response.data.total

              localStorage.cart = JSON.stringify(this.cart)
            } else {
              this.$noty.error('Não foi possível adicionar o produto em seu carrinho, tente novamente.')
            }

            this.loading = false
          })
          .catch(error => {
            if (error) {
              this.$noty.error('Não foi possível adicionar o produto em seu carrinho, tente novamente.')
            }
          })
      }
    }
  },
  mounted() {
  }
};
</script>

<template>
  <b-form @submit.prevent="buyProduct(id)" class="pb-3">
    <select v-if="Object.keys(this.variations).length > 1" v-model="variation" class="custom-select mb-2">
      <option v-for="option in variations" :key="option.id" :value="option.id" :selected="[variation == null ? variation = option.id : false]">{{ option.name }}</option>
    </select>
    <b-button class="btn-block text-uppercase" type="submit" variant="success" :disabled="loading">
      {{ t('Buy') }}
      <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
    </b-button>
  </b-form>
</template>